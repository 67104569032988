import { JobItem } from 'application/interfaces/models/workable';

const TYPE_PREFIX = '[jobListing]';

// eslint-disable-next-line max-len
export const JOB_LISTING_SET_MODULE_LOADED = `${ TYPE_PREFIX } set module loaded`;
export type SetModuleLoaded = {
  type: typeof JOB_LISTING_SET_MODULE_LOADED;
  payload: boolean;
}
export const setModuleLoaded = (
  isLoaded: boolean,
): SetModuleLoaded => ({
  type: JOB_LISTING_SET_MODULE_LOADED,
  payload: isLoaded,
});

export const JOB_LISTING_SET_LOADING = `${ TYPE_PREFIX } set loading`;
export type SetLoading = {
  type: typeof JOB_LISTING_SET_LOADING;
  payload: {
    isLoading: boolean;
  }
};
export const setLoading = (
  isLoading: boolean,
): SetLoading => ({
  type: JOB_LISTING_SET_LOADING,
  payload: {
    isLoading,
  },
});

export const JOB_LISTING_SET_ERROR = `${ TYPE_PREFIX } set error`;
export type SetError = {
  type: typeof JOB_LISTING_SET_ERROR;
  payload: string;
}
export const setError = (
  error: string,
): SetError => ({
  type: JOB_LISTING_SET_ERROR,
  payload: error,
});

export const JOB_LISTING_FETCH_JOBS = `${ TYPE_PREFIX } fetch jobs`;
export type FetchJobsData = {
  type: typeof JOB_LISTING_FETCH_JOBS;
};
export const fetchJobsData = (): FetchJobsData => ({
  type: JOB_LISTING_FETCH_JOBS,
});

export const JOB_LISTING_SET_JOBS = `${ TYPE_PREFIX } set jobs`;
export type SetJobs = {
  type: typeof JOB_LISTING_SET_JOBS;
  payload: JobItem[];
}
export const setJobs = (
  jobs: JobItem[],
): SetJobs => ({
  type: JOB_LISTING_SET_JOBS,
  payload: jobs,
});

export const JOB_LISTING_SET_LOCATIONS = `${ TYPE_PREFIX } set locations`;
export type SetLocations = {
  type: typeof JOB_LISTING_SET_LOCATIONS;
  payload: string[];
}
export const setLocations = (
  locations: string[],
): SetLocations => ({
  type: JOB_LISTING_SET_LOCATIONS,
  payload: locations,
});

export const JOB_LISTING_SET_DEPARTMENTS = `${ TYPE_PREFIX } set departments`;
export type SetDepartments = {
  type: typeof JOB_LISTING_SET_DEPARTMENTS;
  payload: string[];
}
export const setDepartments = (
  departments: string[],
): SetDepartments => ({
  type: JOB_LISTING_SET_DEPARTMENTS,
  payload: departments,
});

export const JOB_LISTING_SET_SEARCH_QUERY = `${ TYPE_PREFIX } set search query`;
export type SetSearchQuery = {
  type: typeof JOB_LISTING_SET_SEARCH_QUERY;
  payload: string;
}
export const setSearchQuery = (
  searchQuery: string,
): SetSearchQuery => ({
  type: JOB_LISTING_SET_SEARCH_QUERY,
  payload: searchQuery,
});

// eslint-disable-next-line max-len
export const JOB_LISTING_SET_FILTER_LOCATIONS = `${ TYPE_PREFIX } set filter locations`;
export type SetFilterLocations = {
  type: typeof JOB_LISTING_SET_FILTER_LOCATIONS;
  payload: string[];
}
export const setFilterLocations = (
  locations: string[],
): SetFilterLocations => ({
  type: JOB_LISTING_SET_FILTER_LOCATIONS,
  payload: locations,
});

// eslint-disable-next-line max-len
export const JOB_LISTING_SET_FILTER_DEPARTMENTS = `${ TYPE_PREFIX } set filter departments`;
export type SetFilterDepartments = {
  type: typeof JOB_LISTING_SET_FILTER_DEPARTMENTS;
  payload: string[];
}
export const setFilterDepartments = (
  departments: string[],
): SetFilterDepartments => ({
  type: JOB_LISTING_SET_FILTER_DEPARTMENTS,
  payload: departments,
});

export type JobListingAction =
  | SetLoading
  | SetError
  | SetModuleLoaded
  | SetJobs
  | SetLocations
  | SetDepartments
  | SetSearchQuery
  | SetFilterLocations
  | SetFilterDepartments
