import { JobItem, JobState } from 'application/interfaces/models/workable';
import { JobListingFilter } from './reducer';

export const extractLocations = (jobs: JobItem[]): string[] => {
  const locations: string[] = [];

  jobs.forEach((jobItem) => {
    if (
      jobItem.state === JobState.Published
      && jobItem.location
      && jobItem.location.locationStr
      && jobItem.location.locationStr.trim() !== ''
    ) {
      locations.push(jobItem.location.locationStr);
    }
  });

  return [...new Set(locations)].sort((a, b) => a.localeCompare(b));
};

export const extractDepartments = (jobs: JobItem[]): string[] => {
  const departments: string[] = [];

  jobs.forEach((jobItem) => {
    if (
      jobItem.state === JobState.Published
      && jobItem.department
      && jobItem.department.trim() !== ''
    ) {
      departments.push(jobItem.department);
    }
  });

  return [...new Set(departments)].sort((a, b) => a.localeCompare(b));
};

/**
 * This function handles UI filtering and searching based on a provided
 * subset of jobsfetched from the Workable API. While not ideal,
 * this approach was implemented due to limitations in the Workable API's
 * response format. The logic used in this function is derived from a shared
 * script that implemented a similar solution on Agility.com
 *
 * @param {JobItem[]} jobs - The subset of jobs fetched from the Workable API.
 * @param {JobListingState} filter - The current filter state of the UI.
 * @returns {JobItem[]} - The filtered and sorted jobs based on the UI filters.
 */
export const buildFilteredJobsList = ({
  jobs,
  filter,
}: {
  jobs: JobItem[]
  filter: JobListingFilter
}): JobItem[] => {
  const filteredJobs: JobItem[] = [];

  jobs.forEach((jobItem) => {
    if (jobItem.state !== JobState.Published || !jobItem.title) {
      return;
    }

    const {
      search: searchQuery,
      locations: locationsFilter,
      departments: departmentsFilter,
    } = filter;
    const hasLocationsFilter = locationsFilter?.length > 0;
    const hasDepartmentsFilter = departmentsFilter?.length > 0;

    if (searchQuery) {
      const jobTitleMatchSearchQuery = jobItem.title.toLowerCase()
        .includes(searchQuery.toLowerCase());

      if (!jobTitleMatchSearchQuery) {
        return;
      }
    }

    if (hasLocationsFilter && hasDepartmentsFilter) {
      locationsFilter.forEach((locationFilter) => {
        departmentsFilter.forEach((departmentFilter) => {
          if (
            jobItem.location?.locationStr === locationFilter
            && jobItem.department === departmentFilter
          ) {
            filteredJobs.push(jobItem);
          }
        });
      });
      return;
    }

    if (hasLocationsFilter) {
      locationsFilter.forEach((locationFilter) => {
        if (jobItem.location?.locationStr === locationFilter) {
          filteredJobs.push(jobItem);
        }
      });
      return;
    }

    if (hasDepartmentsFilter) {
      departmentsFilter.forEach((departmentFilter) => {
        if (jobItem.department === departmentFilter) {
          filteredJobs.push(jobItem);
        }
      });
      return;
    }

    filteredJobs.push(jobItem);
  });

  filteredJobs.sort((jobA, jobB) => {
    const jobAcreatedAtTimestamp = Date.parse(jobA?.createdAt || '') || 0;
    const jobBcreatedAtTimestamp = Date.parse(jobB?.createdAt || '') || 0;

    return jobBcreatedAtTimestamp - jobAcreatedAtTimestamp;
  });

  return filteredJobs;
};
