import { Middleware } from 'redux';

import { Services } from 'application/interfaces';

import {
  LOAD_COMPONENT_SUCCESS,
  LoadComponentSuccess,
} from '../../core/control/actions';
import { getDepartments, getLocations } from './selectors';
import {
  extractDepartments,
  extractLocations,
} from './service';
import {
  FetchJobsData,
  JOB_LISTING_FETCH_JOBS, setDepartments,
  setError,
  setJobs,
  setLoading, setLocations,
  setModuleLoaded,
} from './actions';

export const loadAppmoduleMiddleware = (): Middleware => ({
  dispatch,
}) => (next) => async (action: LoadComponentSuccess): Promise<void> => {
  next(action);
  if (
    action.type === LOAD_COMPONENT_SUCCESS
    && action.payload === 'job-listing'
  ) {
    dispatch(setModuleLoaded(true));
  }
};

export const fetchJobsMiddleware = ({
  logger,
  api,
}: Services): Middleware => ({
  dispatch,
  getState,
}) => (next) => async (action: FetchJobsData): Promise<void> => {
  if (action.type === JOB_LISTING_FETCH_JOBS) {
    if (!api?.workable) {
      logger.info('No Workable api service found');
      return;
    }

    try {
      dispatch(setLoading(true));

      const reduxState = getState();
      const locations = getLocations(reduxState);
      const emptyLocationsList = locations?.length === 0;
      const departments = getDepartments(reduxState);
      const emptyDepartmentsList = departments?.length === 0;

      const results = await api.workable.getJobList();
      const { jobs } = results;

      if (emptyLocationsList) {
        dispatch(setLocations(extractLocations(jobs)));
      }
      if (emptyDepartmentsList) {
        dispatch(setDepartments(extractDepartments(jobs)));
      }

      dispatch(setJobs(jobs));
    } catch (e) {
      dispatch(setError(e.message));
      logger.info(e);
    } finally {
      dispatch(setLoading(false));
    }
  }
  next(action);
};

export default [
  loadAppmoduleMiddleware,
  fetchJobsMiddleware,
];
