import { AppState } from '../../state';
import { initialState, JobListingState } from './reducer';

export const getJobListingModuleLoaded = (
  state: AppState,
): JobListingState['moduleLoaded'] => (
  state?.jobListing
    ? state.jobListing.moduleLoaded
    : initialState.moduleLoaded
);

export const isJobsLoading = (
  state: AppState,
): JobListingState['isLoading'] => (
  state?.jobListing
    ? state.jobListing.isLoading
    : initialState.isLoading
);

export const getJobList = (
  state: AppState,
): JobListingState['jobs'] => (
  state?.jobListing
    ? state.jobListing.jobs
    : initialState.jobs
);

export const getLocations = (
  state: AppState,
): JobListingState['locations'] => (
  state?.jobListing
    ? state.jobListing.locations
    : initialState.locations
);

export const getDepartments = (
  state: AppState,
): JobListingState['departments'] => (
  state?.jobListing
    ? state.jobListing.departments
    : initialState.departments
);

export const getFilter = (
  state: AppState,
): JobListingState['filter'] => (
  state?.jobListing?.filter
    ? state.jobListing.filter
    : initialState.filter
);

export const getSearchQuery = (
  state: AppState,
): JobListingState['filter']['search'] => (
  state?.jobListing?.filter?.search
    ? state.jobListing.filter.search
    : initialState.filter.search
);

export const getFilteredLocations = (
  state: AppState,
): JobListingState['filter']['locations'] => (
  state?.jobListing?.filter?.locations
    ? state.jobListing.filter.locations
    : initialState.filter.locations
);

export const getFilteredDepartments = (
  state: AppState,
): JobListingState['filter']['departments'] => (
  state?.jobListing?.filter?.departments
    ? state.jobListing.filter.departments
    : initialState.filter.departments
);
