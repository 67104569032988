/* eslint-disable camelcase */
export enum JobState {
  Archived = 'archived',
  Published = 'published',
  Draft = 'draft',
  Closed = 'closed',
}
export interface DepartmentHierarchyItem {
  id: number,
  name?: string
}
export enum WorkplaceType {
  OnSite = 'on_site',
  Remote = 'remote',
}
export interface JobLocation {
  location_str?: string,
  country?: string,
  country_code?: string,
  region?: string,
  region_code?: string,
  city?: string,
  zip_code?: string,
  telecommuting?: boolean,
  workplace_type?: WorkplaceType,
}
export interface LocationItem {
  country_code?: string,
  country_name?: string,
  state_code?: string,
  subregion?: string,
  zip_code?: string,
  city?: string,
  coords?: string,
  hidden?: boolean
}
export interface Salary {
  salary_from?: number
  salary_to?: number
  salary_currency?: string,
}

export interface WorkableJobItem {
  id: string,
  title?: string,
  full_title?: string,
  shortcode?: string,
  code?: string,
  state?: JobState,
  sample?: boolean,
  department?: string,
  department_hierarchy: DepartmentHierarchyItem[],
  url?: string,
  application_url?: string,
  shortlink?: string,
  location?: JobLocation,
  locations: LocationItem[],
  salary?: Salary,
  created_at?: string,
  description?: string,
  full_description?: string,
  requirements?: string,
  benefits?: string,
}

export interface WorkableJobListOutput {
  jobs: WorkableJobItem[]
  paging?: {
    next?: string,
  }
}

// converted JobItem for UI
export interface JobItem {
  id: string,
  title?: string,
  department?: string,
  url?: string,
  state?: JobState,
  location?: {
    locationStr?: string,
    country?: string,
    countryCode?: string,
    region?: string,
    regionCode?: string,
    city?: string,
    zipCode?: string,
  },
  createdAt?: string,
}

export interface JobListOutput {
  jobs: JobItem[]
  paging?: {
    next?: string,
  }
}
