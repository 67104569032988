import { JobItem } from 'application/interfaces/models/workable';

import {
  JOB_LISTING_SET_DEPARTMENTS,
  JOB_LISTING_SET_ERROR,
  JOB_LISTING_SET_FILTER_DEPARTMENTS,
  JOB_LISTING_SET_FILTER_LOCATIONS,
  JOB_LISTING_SET_JOBS,
  JOB_LISTING_SET_LOADING,
  JOB_LISTING_SET_LOCATIONS,
  JOB_LISTING_SET_MODULE_LOADED,
  JOB_LISTING_SET_SEARCH_QUERY,
  JobListingAction,
  SetDepartments,
  SetError,
  SetFilterDepartments,
  SetFilterLocations,
  SetJobs,
  SetLoading,
  SetLocations,
  SetModuleLoaded,
  SetSearchQuery,
} from './actions';

export type JobListingFilter = {
  search: string;
  locations: string[];
  departments: string[];
}

export type JobListingState = {
  isLoading: boolean;
  jobs: JobItem[];
  locations: string[];
  departments: string[];
  filter: JobListingFilter;
  error: string;
  moduleLoaded: boolean;
};

export const initialState: JobListingState = {
  isLoading: true,
  jobs: [],
  locations: [],
  departments: [],
  filter: {
    search: '',
    locations: [],
    departments: [],
  },
  error: '',
  moduleLoaded: false,
};

const jobListingReducer = (
  state: JobListingState = initialState,
  action: JobListingAction,
): JobListingState => {
  switch (action.type) {
    case JOB_LISTING_SET_MODULE_LOADED: {
      return {
        ...state,
        moduleLoaded: (action as SetModuleLoaded).payload,
      };
    }
    case JOB_LISTING_SET_LOADING: {
      return {
        ...state,
        isLoading: (action as SetLoading).payload.isLoading,
      };
    }
    case JOB_LISTING_SET_ERROR: {
      return {
        ...state,
        error: (action as SetError).payload,
      };
    }
    case JOB_LISTING_SET_JOBS: {
      return {
        ...state,
        jobs: (action as SetJobs).payload,
      };
    }
    case JOB_LISTING_SET_LOCATIONS: {
      return {
        ...state,
        locations: (action as SetLocations).payload,
      };
    }
    case JOB_LISTING_SET_DEPARTMENTS: {
      return {
        ...state,
        departments: (action as SetDepartments).payload,
      };
    }
    case JOB_LISTING_SET_SEARCH_QUERY: {
      return {
        ...state,
        filter: {
          ...state.filter,
          search: (action as SetSearchQuery).payload,
        },
      };
    }
    case JOB_LISTING_SET_FILTER_LOCATIONS: {
      return {
        ...state,
        filter: {
          ...state.filter,
          locations: (action as SetFilterLocations).payload,
        },
      };
    }
    case JOB_LISTING_SET_FILTER_DEPARTMENTS: {
      return {
        ...state,
        filter: {
          ...state.filter,
          departments: (action as SetFilterDepartments).payload,
        },
      };
    }
    default:
      return state;
  }
};

export default jobListingReducer;
